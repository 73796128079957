// CalendarView.js
import React from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarView.css'; // Import custom CSS
import CustomEvent from './CustomEvent';

const localizer = momentLocalizer(moment);

const CalendarView = ({ workoutDays }) => {
    console.log(workoutDays)
    const events = workoutDays.map((workoutDay) => ({
        title: workoutDay.workouts.code + " - " + workoutDay.workouts.name,
        start: new Date(workoutDay.date),
        end: new Date(workoutDay.date),
        allDay: true,
        resource: workoutDay
    }));

    return (
        <div className="calendar">
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 800 }} // Adjust height for better visibility
                defaultView={Views.MONTH}
                views={['month']}
                components={{
                    event: CustomEvent,
                }}
            />
        </div>
    );
};

export default CalendarView;
