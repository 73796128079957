// CustomEvent.js
import React from 'react';
import './CustomEvent.css'; // Import custom CSS for the event

const CustomEvent = ({event}) => {
    return (
        <div className="custom-event">
            <strong>{event.resource.workouts[0].name}</strong>
            <p>Code: {event.resource.workouts[0].code} [N{event.resource.workouts[0].pl}]</p>
            <p>{Math.floor(event.resource.workouts[0].duration)} minutes [{event.resource.workouts[0].calories} calories]</p>
            <p>Type: {event.resource.workouts[0].groupType} - {event.resource.workouts[0].workoutType}</p>
            <p>{event.resource.workouts[0].isFB ? " [FB] " : event.resource.workouts[0].isLB ? "" +
                "[LB] " : event.resource.workouts[0].isUP ? " [UP] " : ""}{event.resource.workouts[0].isCardio ? " [Cardio] " : ""}
                {event.resource.workouts[0].isCore ? " [Core] " : ""}{event.resource.workouts[0].isLeg ? " [Leg] " : ""}
                {event.resource.workouts[0].isPull ? " [Pull] " : ""}{event.resource.workouts[0].isPush ? " [Push] " : ""}</p>
        </div>
    );
};

export default CustomEvent;
