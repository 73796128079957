import React, {useState} from 'react';
import axios from 'axios';
import './App.css';
import './Workout.css';
import './FitnessForm.css';
import CalendarView from "./CalendarView";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const FitnessForm = ({plData}) => {
    const [trainingPreferences, setTrainingPreferences] = useState([]);
    const [weekdays, setWeekdays] = useState([]);
    // const [workoutsTime, setWorkoutsTime] = useState('LESS_30');
    const [response, setResponse] = useState(null);
    const [error, setError] = useState('');
    const [value, setValue] = useState([20, 35]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handlePreferenceChange = (event) => {
        const value = event.target.value;
        console.log(value)
        setTrainingPreferences(
            trainingPreferences.includes(value)
                ? trainingPreferences.filter((item) => item !== value)
                : [...trainingPreferences, value]
        );
    };

    console.log(trainingPreferences)

    const handleWeekdayChange = (event) => {
        const value = event.target.value;
        setWeekdays(
            weekdays.includes(value)
                ? weekdays.filter((item) => item !== value)
                : [...weekdays, value]
        );
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (trainingPreferences.length === 0) {
            setError('Please select at least one training preference.');
            return;
        }

        if (weekdays.length === 0) {
            setError('Please select at least one weekday.');
            return;
        }

        setError('');

        const requestData = {
            trainingPreference: trainingPreferences,
            trainingDays: weekdays,
            minDuration: value[0],
            maxDuration: value[1],
            xTraining: plData[0],
            cardio: plData[1],
            stretch: plData[2],
            mobility: plData[3],
            core: plData[4],
            strength: plData[5]
        };

        try {
            const result = await axios.post(process.env.REACT_APP_API + '/fitness/v1/ai/plan/test', requestData);
            setResponse(result.data);
        } catch (error) {
            console.error('Error making request:', error);
        }
    };

    function formatLabel(value) {
        if (value === 60) {
            return `${value}+ min`
        }
        return `${value} min`
    }

    return (
        <div className="form-body">
            <form onSubmit={handleSubmit}>
                <div className={"element"}>
                    <label>Training Preferences</label>
                    <div className={"checkbox-list"}>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="CARDIO" onChange={handlePreferenceChange}/> Cardio
                        </label>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="STRENGTH" onChange={handlePreferenceChange}/> Strength
                        </label>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="MOBILITY" onChange={handlePreferenceChange}/> Mobility
                        </label>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="CORE" onChange={handlePreferenceChange}/> Core
                        </label>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="STRETCH" onChange={handlePreferenceChange}/> Stretch
                        </label>
                    </div>
                </div>

                {/*<div className={"element"}>*/}
                {/*    <label>Workouts Time</label>*/}
                {/*    <select value={workoutsTime} onChange={(e) => setWorkoutsTime(e.target.value)}>*/}
                {/*        <option value="LESS_35">LESS 35</option>*/}
                {/*        <option value="FROM_25_TO_47">25 TO 47</option>*/}
                {/*        <option value="OVER_40">OVER 40</option>*/}
                {/*        <option value="LESS_30">LESS 30</option>*/}
                {/*        <option value="FROM_30_TO_45">30 TO 45</option>*/}
                {/*        <option value="OVER_45">OVER 45</option>*/}
                {/*    </select>*/}
                {/*</div>*/}

                <div className={"element"}>
                    <label>Weekdays</label>
                    <div className={"checkbox-list"}>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="MONDAY" onChange={handleWeekdayChange}/> Monday
                        </label>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="TUESDAY" onChange={handleWeekdayChange}/> Tuesday
                        </label>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="WEDNESDAY" onChange={handleWeekdayChange}/> Wednesday
                        </label>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="THURSDAY" onChange={handleWeekdayChange}/> Thursday
                        </label>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="FRIDAY" onChange={handleWeekdayChange}/> Friday
                        </label>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="SATURDAY" onChange={handleWeekdayChange}/> Saturday
                        </label>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="SUNDAY" onChange={handleWeekdayChange}/> Sunday
                        </label>
                    </div>
                </div>

                <Box sx={{ width: 300 }}>
                    <Slider
                        getAriaLabel={() => 'Temperature range'}
                        value={value}
                        min={15}
                        max={60}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        valueLabelFormat={formatLabel}
                    />
                </Box>

                <div className={"button-div"}>
                    {error && <p className="error">{error}</p>}
                    <button className={"submit-button"} type="submit">Generate</button>
                </div>
            </form>

            {response && (
                <div>
                    <CalendarView workoutDays={response}/>
                </div>
            )}
        </div>
    );
};

export default FitnessForm;
